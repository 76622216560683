import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private meta: Meta, private title: Title)
  {
    this.meta.addTags([
      {name: 'description', content: 'Official Website for Reno\'s Lindy Hop Workshop, hosted by Reno Swings!'},
      {name: 'author', content: 'jsiao'},
      {name: 'keywords', content: 'Reno, Romp, Lindy Hop, Reno Romp, The Reno Romp, Reno Swings, Swing Dancing, Nevada, Dance, Things to do in Reno, Reno Dance'}
    ]);
    this.setTitle('Reno, Nevada\'s Official Lindy Hop Workshop');
  }
  public setTitle(newTitle: string){
    this.title.setTitle(newTitle);
  }
  ngOnInit() { }
}
