<div class="page-container">
  <div class="opener">
    <div>
      <p><i>Reno Swings!</i> presents:</p>
      <h1><img src="../assets/media/logo.png"/></h1>
      <p>March 23rd, 2024</p>
      <p>The Ballroom of Reno</p>
    </div>
  </div>
  <div class="tile">
    <div class="level1">
      <div class="level2">
      <!--
	<h2>Get your Tickets Here!</h2>
	<p>Tickets for the Reno Romp are now available! Select either of the tickets below!</p>
	<div class="level3">
	  <a href="https://www.simpletix.com/e/the-reno-romp-tickets-158751">
            <img src="../assets/media/RenoRompWorkShopButton.png"/>
	  </a>
	  <a href="https://www.simpletix.com/e/the-reno-romp-after-dark-tickets-158906">
            <img src="../assets/media/RenoRompAfterDarkButton.png"/>
	  </a>
	</div>
      -->
        <h2>The Biggest Little Lindy Hop Workshop</h2>
        <p>Searching for the next step in your Jitterbug journey? Looking to level-up your Lindy Hop?</p>
	<p><i>Reno Swings!</i> in conjunction with The Ballroom of Reno present the <i>Reno Romp!</i>, a beginner-intermediate level Lindy Hop workshop.</p>
      </div>
    </div>
  </div>
</div>
