<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<!-- Toolbar -->
<div class="omni">
  <nav class="navbar navbar-expand-lg">
    <a class="navbar-brand" href="#"><img src="../assets/media/logo.png"/></a>
    <button type="button" class="navbar-toggler navbar-dark" (click)="toggleNavBar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngClass]="{'show': navbarOpen}" class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="schedule">Schedule</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="about">About</a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="content" role="main">
    <router-outlet></router-outlet>
  </div>
  <footer>
    <p>Website made in vim.</p>
  </footer>
</div>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

