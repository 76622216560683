import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  navbarOpen = false;
  constructor()
  {
    if (window.innerWidth >= 767)
    {
      this.navbarOpen = true;
    }
  }
  toggleNavBar()
  {
    this.navbarOpen = !this.navbarOpen;
  }
}
