import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// Routing Links 

import { HomeComponent } from './home/home.component';
const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'schedule',
   loadChildren: () => import('./schedule/schedule-routing.module').then(mod => mod.ScheduleRoutingModule)
  },
  {
    path: 'about',
   loadChildren: () => import('./about/about-routing.module').then(mod => mod.AboutRoutingModule)
  }
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      {
        useHash: true,
	enableTracing: true
      }
    )
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
